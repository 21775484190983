
.skill-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 16px;
  padding: 16px 20px;
  height: 48px;
  font-size: 16px;
  border-radius: 24px;
  background: #dfdfdf;
  color: #5B5B5B;
  cursor: pointer;
}

.skill-wrapper:hover {
  color: #5B5B5B;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 16px;
  padding: 16px 20px;
  height: 48px;
  font-size: 16px;
  border-radius: 24px;
  background: #cbcbcb;
  cursor: pointer;
  box-shadow: #9A9A9A;
}
.skill-wrapper.level {
  padding-left: 8px;
}
.skill-wrapper.selected {
  background: #23C3F3;
  color: white;
}
.skill-wrapper.level span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  font-size: 10px;
  border-radius: 50%;
  background: #FFFFFF;
}
.skill-wrapper.orange span {
  color: #FA6400;
}
.skill-wrapper.blue span {
  color: #23C3F3;
}
.skill-wrapper.magenta span {
  color: #F03D75;
}
.skill-wrapper.green span {
  color: #48C63F;
}
.skill-wrapper.purple span {
  color: #5C3FC1;
}
.skill-wrapper.orange {
  color: white;
  background: #FA6400;
}
.skill-wrapper.blue {
  color: white;
  background: #23C3F3;
}
.skill-wrapper.magenta {
  color: white;
  background: #F03D75;
}
.skill-wrapper.green {
  color: white;
  background: #48C63F;
}
.skill-wrapper.purple {
  color: white;
  background: #5C3FC1;
}
.skillButton {
  display:block
}
.skillButtonNone {
  display:none
}
