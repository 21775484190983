

.main-form {
	text-align: center;
	justify-content: center;
}

.account-type,
.org-type {
	display: flex;
	width: 80%;
	margin: 0 auto;
	/*margin-bottom: 20px;*/
	justify-content: space-between;
}

.org-type {
	/*margin-top: 20px;*/
	margin-bottom: -5px;
}

.TandC {
	margin-top: 15px;
}

.TandC > p {
	margin-bottom: 0;
}

.TandC > p > strong > a {
	text-decoration: underline;
	font-weight: lighter;
	color: black;
}

.btn-class {
	display: block;
	width: 100%;
}

.error_msg {
	text-align: left;
    color: red;
    font-size: smaller;
}