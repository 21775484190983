/* Home page */
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

.homeTitle {
	color: rgb(58, 65, 75);
    font-size: xx-large;
    font-weight: 900;
	line-height: 1.55;
    margin-bottom: 23px;
}
.infoRow {
	margin-top: 90px;
}
.second-left-side {
	background: url("/assets/img/second-left-bg.png") no-repeat;
	background-size: cover;
	height: 415px;
}

.second-left-heading {
	font-size: 27px;
	font-weight: 900;
}

.second-right-side {
	height: 430px;
	width: 730px;
}

.announcement {
	padding: 10px;
	background-color: #142133;
	width: auto;
	color: #ffffff;
	border: 2px solid #f8f8f9;
	height: auto;
}
.announcement h5 {
	font-weight: bold;
}

.cancelButton {
	text-align: center;
	align-self: center;
}
.cancelButton :hover {
	cursor: pointer;
}
.circle-data {
	color: #3a414b;
}
.circleImages {
	float: left;
	background-color: #f8f8f9;
	border-radius: 50px;
	height: 75px;
	width: 75px;
	margin-right: 5px;
}
.circleHeading {
	font-family:  'Poppins', sans-serif;
	font-size: 16px;
	text-align: left;
	margin: 0px;
	padding-top: 8px;
	color: #4E6174;
	font-weight: bolder;
}
.circleData {
	color: #4E6174;
	font-family:  'Poppins', sans-serif;
	font-size: 14px;
	text-align: left;
}
.awesomeSlider {
	max-width: inherit !important;
}
.awssld__content {
	max-width: inherit !important;
	background-color: white !important;
}
.awssld__container {
	display: block;
	width: 100%;
	height: 0;
	padding-bottom: 700px !important;
}
.awssld__box {
	display: block;
	position: absolute;
	width: 100%;
	height: 80% !important;
	top: 0;
	left: 0;
}

.awssld__timer {
	background-color: transparent !important; // to disable loader
}
// for mobiles
@media screen and (min-width: 320px) and (max-width: 480px) {
	.announcement {
		padding: 1px;
	}
	.announcement h5 {
		font-size: 15px;
	}
	.infoRow {
		margin-top: 10px;
	}
	.homeTitle {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.awssld__container {
		padding-bottom: 1150px !important;
	}
	.second-left-side {
		height: 415px;
		margin-bottom: 10px;
	}

	.second-left-heading {
		font-size: 20px;
	}

	.second-right-side {
		height: 280px;
		width: inherit;
	}
}
// for tabs
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.awssld__container {
		padding-bottom: 700px !important;
	}

	.second-left-side {
		height: 415px;
	}

	.second-left-heading {
		font-size: 20px;
	}

	.second-right-side {
		height: 430px;
		width: inherit;
	}
}
