@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import '../component/NavBar';
@import '../component/Home';
@import '../component/QuickFacts';
@import '../component/DataInsights';
@import '../component/Individual';
@import '../component/Organization';
@import '../component/Footer';
@import '../component/jobSeeker';
@import '../component/SkillButton';
@import '../component/charPage';

body {
	background-color: #F9FAFC !important;
	//background: linear-gradient(to bottom, #f9fafc 17%, #ffffff 0%);
}

.pointerCursor {
	cursor: pointer;
}

.noneDisplay {
	display: none;
}
.fontWeight400 {
	font-weight: 400;
}
.fontWeight600 {
	font-weight: 600;
}
.fontWeight700 {
	font-weight: 700;
}
.fontWeight900 {
	font-weight: 900;
}
body {
	font-family: poppins;
	color: #4e6174;
}

/* Individual & Organization */
.common-left-menu {
	color: #a6b0b9;
	text-align: left;
}

/* ENDS Individual & Organization */

.whiteHeading {
	padding: 10vh;
	background-color: white;
	text-align: left;
	padding-bottom: 1vh;
}

.insight-common-img {
	transform: translate(0%, 0%);
}

.insights-image-text,
.data-insight-image,
.data-insight-video {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.data-insight-video {
	background-color: white;
	border-radius: 5px;
	height: 50vh;
	width: inherit;
}

.insight-video-image {
	border-radius: 5px;
	margin-bottom: 0rem;
	width: inherit;
	height: inherit;
}

.insight-video-title {
	color: #d6dbdf;
	font-size: 0.9rem;
}

.insights-image-left {
	//width: 65px;
	//height: 65px;
	border-radius: 10px;
	width: inherit;
	height: inherit;
}
.loader-inBetween {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	background-color: transparent;
	color: white;
	font-size: 16px;
	padding: 2px 14px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
}

// ARROW IMAGE
.arrowImage {
	text-align: center;
}
.arrow {
	float: left;
	background-size: 150px 200px;
	height: 200px;
	width: 150px;
}
.arrow:hover {
	box-shadow: 2px 2px 5px black;
	cursor: pointer;
}
.arrow1 {
	background-image: url('/assets/img/arrow1.png');
}
.arrow2 {
	background-image: url('/assets/img/arrow2.png');
}
.arrow3 {
	background-image: url('/assets/img/arrow3.png');
}
.arrow4 {
	background-image: url('/assets/img/arrow4.png');
}
.arrow5 {
	background-image: url('/assets/img/arrow5.png');
}
.arrow6 {
	background-image: url('/assets/img/arrow6.png');
}
.leftDiv {
	padding-top: 170px;
	text-align: center;
	color: white;
	height: 468px;
	width: 100%;
	background-color: #4e6174;
}
.leftDiv:hover {
	box-shadow: 2px 2px 5px black;
	cursor: pointer;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	background-color: transparent;
	border: 2px solid black;
	border-radius: 20px;
	color: red;
	height: 10px;
	margin: 0 2px;
	min-width: 36px;
	padding: 8px 12px;
	font-weight: bolder;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
	background-color: red;
	border-color: black;
	color: white;
}

.bulb-image {
	border-radius: 50%;
	background-color: #eceef0;
	height: 100px;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.select-account {
	font-size: 14px;
	font-weight: 600;
	text-align: center;
}

.select-profile-item {
	//border: 2px solid gray;
	box-shadow: 0px 0px 8px 0px #80808052;
	margin: 18px;
	padding: 8px 12px;
	border-radius: 6px;
	align-items: center;
	min-width: 240px;
	cursor: pointer;
	//justify-content: center;

	&:hover {
		box-shadow: 0px 0px 8px 4px #80808052;
	}

	.MuiAvatar-root {
		margin: 5px;
	}

	div.info {
		margin-left: 6px;
		label.name {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
		}
		span.type {
			font-size: 12px;
			font-weight: 600;
			p {
				margin: 0;
			}
			&.b2b {
				color: #09ceba;
			}
			&.b2c {
				color: #911d25;
			}
		}
	}
}