/* Header */
.logoClass {
  line-height: 7vh;
}
.imageClass {
  height: inherit;
  width: 170px;
}
.navLoginBtn {
  padding: 5px 40px !important;
}
.navRegisterBtn {
  padding: 5px 40px !important;
}
.navbar-padding {
  padding: 1.5rem 0.25rem;
}
@media screen and (min-width: 321px) and (max-width: 480px) {
  .navLoginBtn {
    margin-top: 5px;
    border: none !important ;
    padding: 5px 20px !important;
  }
  .navRegisterBtn {
    margin-top: 5px;
    padding: 5px 20px !important;
  }
  .imageClass {
    width: 130px;
  }
  .navbar-padding {
    padding: 0.40rem 0.25rem;
  }
  .navLoginBtn {
    font-weight: bolder!important;
  }
  .navRegisterBtn {
    font-weight: bolder!important;
  }
}

@media screen and (max-width: 320px) {
  .imageClass {
    height: inherit;
    width: 130px;
  }
  .navLoginBtn {
    padding: 5px 5px !important;
  }
  .navRegisterBtn {
    padding: 5px 5px !important;
  }
}