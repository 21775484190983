
.right-facts-section {
  padding: 1.7rem;
}

.list-data {
  max-height: 50rem;
  overflow-y: scroll;
}

.right-facts-content {
  padding: 1.2rem;
}
.toggle {
  width: 130px;
  float: right;
}

.right-tab-view {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid #4e6174;
  width: auto;
  border-radius: 5px;
}


.tab-inactive {
  color: black;
  background-color: unset;
  border-color: #ff000000;
}

.tab-inactive:hover {
  color: #fff;
  text-decoration: none;
}

.tab-active {
  background-color: #4e6174;
  border-color: #4e6174;
  color: #fff !important;
}

.tab-active:hover {
  background-color: #4e6174;
  border-color: #4e6174;
  pointer-events: none;
}

.tab-active:focus{
  box-shadow: none;
}

.tab-table {
  color: inherit
}

.first-td{
  font-weight: 600;
}

.content {
  overflow: auto;
}

/*SEARCH BOX*/
.css-1waqmdr-InputComponent {
  width: calc(2ch + 10px) !important;
}
.css-d41fhq-InputComponent {
  width: calc(1ch + 10px) !important;
}

/*SEARCH BOX ENDS*/

/*WORLD MAP*/

.examples {
  width: 1000px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  text-align: center;

  &__block {
    margin-bottom: 80px;
    padding-bottom: 80px;
    border-bottom: 1px dotted black;

    &__map {
      display: inline-block;
      width: 500px;

      //&--stroke {
      //  .svg-map {
      //    stroke-width: 0.5 !important; // Australia stroke is larger than other maps
      //  }
      //}

      &__tooltip {
        position: fixed;
        width: auto;
        min-width: 200px;
        padding: 10px;
        //overflow-x: scroll;
        border: 1px solid darkgray;
        background-color: #fefefe;
        box-shadow: 5px 5px 10px black;

      }
    }
  }
}
.mapColor {
  stroke:whitesmoke;
  fill:#3a414b;
  box-sizing: border-box;
}
.svg-map {
  stroke-width: 5px !important; // Australia stroke is larger than other maps
}

/*WORLD MAP ENDS*/

// for mobile phones
@media screen and (min-width: 320px) and (max-width: 480px){
  .examples__block__map__tooltip {
    position: sticky;
  }
  .list-data {
    max-height: 60rem;
  }
  .toggle {
    float:none;
    width:inherit;
  }
}
// for tabs
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .examples__block__map__tooltip {
    position: sticky;
  }
  .list-data {
    max-height:60rem;
  }
  .toggle {
    width:inherit;
    float:none;
  }
}
