.assess-skills-div {
	height: 100%;
	padding: 5vh 0;
	color: grey;
}

.col-assess-skills {
	padding: 0px;
	background-color: #fbfbfc;
	margin-left: 10vh;
}
.assess-skills {
	height: 100%;
	border-radius: 14px;
	//align-items: center;
	//justify-content: center;
}
.left-title {
	text-align: left;
	height: 3rem;
	padding-top: 25px;
	padding-left: 9%;
	font-weight: bold;
}
.assess-skills-progbar {
	width: inherit;
	margin: 0px;
	height: 20px;
}
.assess-skills-content {
	width: inherit;
	margin-top: 5%;
	text-align: left;
	padding-left: 6%;
}
.assess-skills-content p {
	margin: 0px 0px 0px 20px;
	font-weight: 500;
}

.assess-skills-button {
	margin-top: 30px;
	padding-left: 55px;
}
.btn-previous {
	padding: 5px 20px;
	margin-right: 3px;
	border: 2px solid #107bfe;
	float: left;
}
.btn-nxt {
	padding: 5px 40px;
	border: 2px solid #107bfe;
	float: left;
}
.btn-submit {
	//float:right;
	padding: 5px 30px;
	font-weight: bold;
}
.exitSurvey:hover {
	cursor: pointer;
	font-weight: bold;
}
.prog-percent {
	float: left;
	text-align: right;
	padding-right: 35px;
	width: 13%;
}
.prog-percent-bar {
	float: left;
	width: 70%;
	margin-left: -10px;
	margin-right: -30px;
}

.learn-skills {
	background-color: inherit;
	height: 100%;
	border-radius: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.learn-skills video {
	width: 100% !important;
	height: inherit !important;
}
.learn-skills-div {
	padding: 15vh 12vh;
	color: grey;
	height: 90vh;
}

// JOBS
.insights-image-job {
	height: 75px;
	width: 75px;
}
.assess-jobs-div {
	height: 90vh;
	padding: 5vh 6vh 15vh 6vh;
	color: grey;
	//overflow-y: scroll;
}
.assess-jobs-div-public {
	height: 90vh;
	padding: 5vh 6vh 15vh 6vh;
	color: grey;
	overflow-y: scroll;
}
.assess-jobs {
	background-color: #fff;
	height: auto;
	border-radius: 14px;
}
.job-card {
	min-height: 200px;
	max-height: inherit;
	padding: 15px 15px 0px 15px;
	border: 1px solid black;
	margin-bottom: 10px;
	//  important to arrange items in center
	/*  display: flex;
  align-items: center;
  justify-content: center;*/
}
.job-card-public {
	min-height: 150px;
	max-height: inherit;
	padding: 15px 15px 0px 15px;
}

.job-card-info p {
	margin: 0px;
}
.job-card-lightFont {
	color: #3a414b;
	font-weight: 600;
}
.job-card-grey-image {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: rgb(246, 247, 248);
	border-radius: 8px;
	height: 80%;
	width: inherit;
}
.job-card-org-image {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	//background-color: rgb(246, 247, 248);
	border-radius: 8px;
	height: 80%;
	width: inherit;
}
.hr-0 {
	margin: 0px;
	padding: 0px 10px 0px 10px;
}
.wide-screen-icon {
	display: block;
}
.narrow-screen-icon {
	display: none;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
	.assess-skills-div {
		padding: 5vh 3vh;
	}
	.learn-skills-div {
		padding: 5vh 3vh;
	}
	.btn-sub {
		margin-top: 10px;
		text-align: center;
	}
	.btn-previous {
		float: none;
	}
	.btn-nxt {
		float: none;
	}
	.btn-prev-nxt {
		text-align: center;
	}
	.prog-percent {
		width: 13%;
		padding-right: 0px;
	}
	.prog-percent-bar {
		margin-left: 10px;
		width: 80%;
	}
	.wide-screen-icon {
		display: none;
	}
	.narrow-screen-icon {
		display: block;
	}
	.job-card-grey-image {
		height: 100%;
	}
	.assess-jobs-div {
		padding: 5vh 3vh 15vh 3vh;
	}
	.assess-jobs-div-public {
		padding: 5vh 3vh 15vh 3vh;
	}
	.job-card {
		padding: 15px;
	}
	.assess-skills-content {
		padding-left: 0px;
	}
	.assess-skills-button {
		padding-left: 0px;
	}
	.learn-skills-div {
		padding: 5vh 5vh;
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.assess-skills-content {
		padding-left: 0px;
	}
	.assess-skills-button {
		padding-left: 0px;
	}
	.learn-skills-div {
		padding: 5vh 5vh;
	}
}
