.card-jobSeeker {
  height: 100px;
  box-shadow: 2px 2px 7px black;
}

.card-jobSeeker:hover {
  background-color: #f8f8f9;
  box-shadow: 2px 2px 10px black;
  cursor: pointer;
}


.tabs {
  background-color: #f3f1f1;
  border:2px solid black;
}
.job-active {
  padding: 11px ;
  background-color: bisque;
  color:black;
}
.label-btn {
  margin-top: 10px;
  padding-top: 0;
  width: 90px;
  height: 25px;
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  border-radius: 18px;
  border: none;
  background: #e1e1e1;
}
.bookMark:hover {
  cursor:pointer
}
.action-button-btn {
  padding-top: 0;
  width: auto;
  height: 30px;
  font-size: 14px;
  font-weight: 200;
  color: #FFFFFF;
  border-radius: 18px;
  border: none;
  background: #23C3F3;
}
.action-buttons {
  display: none !important;
  background-color: #efefef;
  border: 2px solid #dfdfdf;
  transform: translate(0, 24px);
}

.skill-wrapper:hover {
  .action-buttons {
    position: absolute;
    display: inline-block !important;
    margin-top: 35px;
  }
}

.action-buttons:hover {
  position: absolute;
  display: inline-block !important;
  margin-top: 35px;
}
