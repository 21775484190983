.active-skills {
	color: #1284ff;
}
.active-skills-data {
	color: #464d56;
}
.radar-chart-div {
	padding: 9vh 3vh 10vh 3vh;
	color: grey;
	min-height: 590px;
}
.assessWorkforce {
	border-radius: 15px;
	background-image: url("/assets/img/AssessWorkForce.png");
	background-repeat: no-repeat;
	height: 550px;
	width: 100%;
	background-size: contain;
}
.upskillWorkforce {
	background-image: url("/assets/img/AssessWorkforcesvg.svg");
	height: 570px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
}
.table-head {
	border-bottom: 2px solid #8080807a;
	border-left: 2px solid #8080807a;
}
.table-row-left {
	border-top: 2px solid #8080807a;
	border-right: 2px solid #8080807a;
	font-size: 15px;
	font-weight: Bold;
}
.org-right-div {
	padding: 9vh 9vh 0 9vh;
	color: grey;
}
.org-right-col {
	margin-left: 10vh;
}

// for mobiles
@media screen and (min-width: 320px) and (max-width: 480px) {
	.org-right-div {
		padding: 5vh 2vh 2vh 2vh;
		color: grey;
	}
	.upskillWorkforce {
		background-image: url("/assets/img/AssessWorkforcesvg.svg");
		height: 270px;
		width: 100%;
		background-size: auto;
	}
}
// for tabs
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.org-right-div {
		padding: 5vh 2vh 2vh 2vh;
		color: grey;
	}
	.upskillWorkforce {
		background-image: url("/assets/img/AssessWorkforcesvg.svg");
		background-size: auto;
		height: 570px;
		width: 100%;
	}
}
