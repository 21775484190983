.page-protector {
    height: 98vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
}

.mission-control {
    body {
        background: white !important;
    }
}

.legacy-TS-div {
    background: rgba(60, 136, 255, 0.23);
    border-radius: 4px;
    padding: 10px;
}
.new-TS-div {
    background: rgba(255, 0, 0, 0.38);
    border-radius: 4px;
    padding: 10px;
}

.ts-admin-url {
    //font-size: 12px;
    word-break: break-word;
}
