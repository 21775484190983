.post-details-page {
	height: 80vh;

	.page-body {
		padding-top: 20px;
		overflow: scroll;

		-ms-overflow-style: none;
		scrollbar-width: none;

		.back-icon {
			margin-left: 20px;
			margin-bottom: 30px;
			font-size: xx-large;
		}

		.body__image {
			display: flex;
			flex-flow: column;
			align-items: center;

			.inner__container {
				height: 400px;
				width: 95%;

				img {
					border-radius: 16px;
					height: 100%;
					width: 100%;
				}
			}
		}

		.body__title {
			margin: 10px 0;
			text-align: left;
			margin: 10px 39px;
		}

		.body__description {
			text-align: justify;
			margin: 10px 39px;
		}
	}

	.page-body::-webkit-scrollbar {
		display: none;
	}
}
