@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900);
/* Header */
.logoClass {
  line-height: 7vh; }

.imageClass {
  height: inherit;
  width: 170px; }

.navLoginBtn {
  padding: 5px 40px !important; }

.navRegisterBtn {
  padding: 5px 40px !important; }

.navbar-padding {
  padding: 1.5rem 0.25rem; }

@media screen and (min-width: 321px) and (max-width: 480px) {
  .navLoginBtn {
    margin-top: 5px;
    border: none !important;
    padding: 5px 20px !important; }
  .navRegisterBtn {
    margin-top: 5px;
    padding: 5px 20px !important; }
  .imageClass {
    width: 130px; }
  .navbar-padding {
    padding: 0.40rem 0.25rem; }
  .navLoginBtn {
    font-weight: bolder !important; }
  .navRegisterBtn {
    font-weight: bolder !important; } }

@media screen and (max-width: 320px) {
  .imageClass {
    height: inherit;
    width: 130px; }
  .navLoginBtn {
    padding: 5px 5px !important; }
  .navRegisterBtn {
    padding: 5px 5px !important; } }

/* Home page */
.homeTitle {
  color: #3a414b;
  font-size: xx-large;
  font-weight: 900;
  line-height: 1.55;
  margin-bottom: 23px; }

.infoRow {
  margin-top: 90px; }

.second-left-side {
  background: url("/assets/img/second-left-bg.png") no-repeat;
  background-size: cover;
  height: 415px; }

.second-left-heading {
  font-size: 27px;
  font-weight: 900; }

.second-right-side {
  height: 430px;
  width: 730px; }

.announcement {
  padding: 10px;
  background-color: #142133;
  width: auto;
  color: #ffffff;
  border: 2px solid #f8f8f9;
  height: auto; }

.announcement h5 {
  font-weight: bold; }

.cancelButton {
  text-align: center;
  align-self: center; }

.cancelButton :hover {
  cursor: pointer; }

.circle-data {
  color: #3a414b; }

.circleImages {
  float: left;
  background-color: #f8f8f9;
  border-radius: 50px;
  height: 75px;
  width: 75px;
  margin-right: 5px; }

.circleHeading {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  text-align: left;
  margin: 0px;
  padding-top: 8px;
  color: #4E6174;
  font-weight: bolder; }

.circleData {
  color: #4E6174;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-align: left; }

.awesomeSlider {
  max-width: inherit !important; }

.awssld__content {
  max-width: inherit !important;
  background-color: white !important; }

.awssld__container {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 700px !important; }

.awssld__box {
  display: block;
  position: absolute;
  width: 100%;
  height: 80% !important;
  top: 0;
  left: 0; }

.awssld__timer {
  background-color: transparent !important; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .announcement {
    padding: 1px; }
  .announcement h5 {
    font-size: 15px; }
  .infoRow {
    margin-top: 10px; }
  .homeTitle {
    font-size: 16px;
    margin-bottom: 10px; }
  .awssld__container {
    padding-bottom: 1150px !important; }
  .second-left-side {
    height: 415px;
    margin-bottom: 10px; }
  .second-left-heading {
    font-size: 20px; }
  .second-right-side {
    height: 280px;
    width: inherit; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .awssld__container {
    padding-bottom: 700px !important; }
  .second-left-side {
    height: 415px; }
  .second-left-heading {
    font-size: 20px; }
  .second-right-side {
    height: 430px;
    width: inherit; } }

.right-facts-section {
  padding: 1.7rem; }

.list-data {
  max-height: 50rem;
  overflow-y: scroll; }

.right-facts-content {
  padding: 1.2rem; }

.toggle {
  width: 130px;
  float: right; }

.right-tab-view {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid #4e6174;
  width: auto;
  border-radius: 5px; }

.tab-inactive {
  color: black;
  background-color: unset;
  border-color: #ff000000; }

.tab-inactive:hover {
  color: #fff;
  text-decoration: none; }

.tab-active {
  background-color: #4e6174;
  border-color: #4e6174;
  color: #fff !important; }

.tab-active:hover {
  background-color: #4e6174;
  border-color: #4e6174;
  pointer-events: none; }

.tab-active:focus {
  box-shadow: none; }

.tab-table {
  color: inherit; }

.first-td {
  font-weight: 600; }

.content {
  overflow: auto; }

/*SEARCH BOX*/
.css-1waqmdr-InputComponent {
  width: calc(2ch + 10px) !important; }

.css-d41fhq-InputComponent {
  width: calc(1ch + 10px) !important; }

/*SEARCH BOX ENDS*/
/*WORLD MAP*/
.examples {
  width: 1000px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  text-align: center; }
  .examples__block {
    margin-bottom: 80px;
    padding-bottom: 80px;
    border-bottom: 1px dotted black; }
    .examples__block__map {
      display: inline-block;
      width: 500px; }
      .examples__block__map__tooltip {
        position: fixed;
        width: auto;
        min-width: 200px;
        padding: 10px;
        border: 1px solid darkgray;
        background-color: #fefefe;
        box-shadow: 5px 5px 10px black; }

.mapColor {
  stroke: whitesmoke;
  fill: #3a414b;
  box-sizing: border-box; }

.svg-map {
  stroke-width: 5px !important; }

/*WORLD MAP ENDS*/
@media screen and (min-width: 320px) and (max-width: 480px) {
  .examples__block__map__tooltip {
    position: -webkit-sticky;
    position: sticky; }
  .list-data {
    max-height: 60rem; }
  .toggle {
    float: none;
    width: inherit; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .examples__block__map__tooltip {
    position: -webkit-sticky;
    position: sticky; }
  .list-data {
    max-height: 60rem; }
  .toggle {
    width: inherit;
    float: none; } }

/* Data Insights and trends */
.insights {
  color: #4e6074;
  background-color: white;
  width: inherit;
  padding: 0vh 5vh;
  margin: 0px;
  font-size: medium; }

.headingData {
  margin-bottom: 50px;
  font-size: x-large;
  color: #4e6074; }

.trends {
  padding: 2vh;
  padding-top: 3vh;
  margin-top: 50px;
  background-color: #293343;
  color: white; }

.below-visual {
  margin-bottom: 4rem; }

.videobutton {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 2px 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px; }

.modalPlayer {
  max-width: 500px;
  width: auto;
  height: 50%;
  padding: unset;
  background: black; }

.customWidth {
  height: 30% !important;
  width: 30% !important; }

.customWidthList {
  height: 50% !important;
  width: 50% !important; }

.customWidthImage {
  height: inherit; }

.customBackground {
  background-color: #f6f7f8 !important; }

#video {
  object-fit: initial;
  width: inherit;
  height: inherit; }

.insights-image-text {
  background-color: #f6f7f8;
  border-radius: 8px;
  height: 97px;
  width: inherit; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .insights {
    padding: 0vh 1vh;
    text-align: center; }
  .headingData {
    margin-bottom: 5rem; }
  .below-visual {
    margin-bottom: 4rem; }
  .customWidth {
    height: auto !important;
    width: auto !important; }
  .customWidthImage {
    width: 100% !important; }
  .customWidthList {
    height: 60px !important; }
  .insights-image-text {
    background-color: #f6f7f8;
    border-radius: 8px;
    height: 100%;
    width: inherit; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .insights-image-text {
    background-color: #f6f7f8;
    border-radius: 8px;
    height: 100%;
    width: inherit; } }

.assess-skills-div {
  height: 100%;
  padding: 5vh 0;
  color: grey; }

.col-assess-skills {
  padding: 0px;
  background-color: #fbfbfc;
  margin-left: 10vh; }

.assess-skills {
  height: 100%;
  border-radius: 14px; }

.left-title {
  text-align: left;
  height: 3rem;
  padding-top: 25px;
  padding-left: 9%;
  font-weight: bold; }

.assess-skills-progbar {
  width: inherit;
  margin: 0px;
  height: 20px; }

.assess-skills-content {
  width: inherit;
  margin-top: 5%;
  text-align: left;
  padding-left: 6%; }

.assess-skills-content p {
  margin: 0px 0px 0px 20px;
  font-weight: 500; }

.assess-skills-button {
  margin-top: 30px;
  padding-left: 55px; }

.btn-previous {
  padding: 5px 20px;
  margin-right: 3px;
  border: 2px solid #107bfe;
  float: left; }

.btn-nxt {
  padding: 5px 40px;
  border: 2px solid #107bfe;
  float: left; }

.btn-submit {
  padding: 5px 30px;
  font-weight: bold; }

.exitSurvey:hover {
  cursor: pointer;
  font-weight: bold; }

.prog-percent {
  float: left;
  text-align: right;
  padding-right: 35px;
  width: 13%; }

.prog-percent-bar {
  float: left;
  width: 70%;
  margin-left: -10px;
  margin-right: -30px; }

.learn-skills {
  background-color: inherit;
  height: 100%;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.learn-skills video {
  width: 100% !important;
  height: inherit !important; }

.learn-skills-div {
  padding: 15vh 12vh;
  color: grey;
  height: 90vh; }

.insights-image-job {
  height: 75px;
  width: 75px; }

.assess-jobs-div {
  height: 90vh;
  padding: 5vh 6vh 15vh 6vh;
  color: grey; }

.assess-jobs-div-public {
  height: 90vh;
  padding: 5vh 6vh 15vh 6vh;
  color: grey;
  overflow-y: scroll; }

.assess-jobs {
  background-color: #fff;
  height: auto;
  border-radius: 14px; }

.job-card {
  min-height: 200px;
  max-height: inherit;
  padding: 15px 15px 0px 15px;
  border: 1px solid black;
  margin-bottom: 10px;
  /*  display: flex;
  align-items: center;
  justify-content: center;*/ }

.job-card-public {
  min-height: 150px;
  max-height: inherit;
  padding: 15px 15px 0px 15px; }

.job-card-info p {
  margin: 0px; }

.job-card-lightFont {
  color: #3a414b;
  font-weight: 600; }

.job-card-grey-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f6f7f8;
  border-radius: 8px;
  height: 80%;
  width: inherit; }

.job-card-org-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  height: 80%;
  width: inherit; }

.hr-0 {
  margin: 0px;
  padding: 0px 10px 0px 10px; }

.wide-screen-icon {
  display: block; }

.narrow-screen-icon {
  display: none; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .assess-skills-div {
    padding: 5vh 3vh; }
  .learn-skills-div {
    padding: 5vh 3vh; }
  .btn-sub {
    margin-top: 10px;
    text-align: center; }
  .btn-previous {
    float: none; }
  .btn-nxt {
    float: none; }
  .btn-prev-nxt {
    text-align: center; }
  .prog-percent {
    width: 13%;
    padding-right: 0px; }
  .prog-percent-bar {
    margin-left: 10px;
    width: 80%; }
  .wide-screen-icon {
    display: none; }
  .narrow-screen-icon {
    display: block; }
  .job-card-grey-image {
    height: 100%; }
  .assess-jobs-div {
    padding: 5vh 3vh 15vh 3vh; }
  .assess-jobs-div-public {
    padding: 5vh 3vh 15vh 3vh; }
  .job-card {
    padding: 15px; }
  .assess-skills-content {
    padding-left: 0px; }
  .assess-skills-button {
    padding-left: 0px; }
  .learn-skills-div {
    padding: 5vh 5vh; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .assess-skills-content {
    padding-left: 0px; }
  .assess-skills-button {
    padding-left: 0px; }
  .learn-skills-div {
    padding: 5vh 5vh; } }

.active-skills {
  color: #1284ff; }

.active-skills-data {
  color: #464d56; }

.radar-chart-div {
  padding: 9vh 3vh 10vh 3vh;
  color: grey;
  min-height: 590px; }

.assessWorkforce {
  border-radius: 15px;
  background-image: url("/assets/img/AssessWorkForce.png");
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  background-size: contain; }

.upskillWorkforce {
  background-image: url("/assets/img/AssessWorkforcesvg.svg");
  height: 570px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover; }

.table-head {
  border-bottom: 2px solid #8080807a;
  border-left: 2px solid #8080807a; }

.table-row-left {
  border-top: 2px solid #8080807a;
  border-right: 2px solid #8080807a;
  font-size: 15px;
  font-weight: Bold; }

.org-right-div {
  padding: 9vh 9vh 0 9vh;
  color: grey; }

.org-right-col {
  margin-left: 10vh; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .org-right-div {
    padding: 5vh 2vh 2vh 2vh;
    color: grey; }
  .upskillWorkforce {
    background-image: url("/assets/img/AssessWorkforcesvg.svg");
    height: 270px;
    width: 100%;
    background-size: auto; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .org-right-div {
    padding: 5vh 2vh 2vh 2vh;
    color: grey; }
  .upskillWorkforce {
    background-image: url("/assets/img/AssessWorkforcesvg.svg");
    background-size: auto;
    height: 570px;
    width: 100%; } }

/* Footer */
.footer {
  padding-top: 5px;
  font-weight: 900;
  font-size: small; }

.footerLink {
  width: 100% !important;
  cursor: default;
  color: white;
  text-decoration: none; }

.card-jobSeeker {
  height: 100px;
  box-shadow: 2px 2px 7px black; }

.card-jobSeeker:hover {
  background-color: #f8f8f9;
  box-shadow: 2px 2px 10px black;
  cursor: pointer; }

.tabs {
  background-color: #f3f1f1;
  border: 2px solid black; }

.job-active {
  padding: 11px;
  background-color: bisque;
  color: black; }

.label-btn {
  margin-top: 10px;
  padding-top: 0;
  width: 90px;
  height: 25px;
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  border-radius: 18px;
  border: none;
  background: #e1e1e1; }

.bookMark:hover {
  cursor: pointer; }

.action-button-btn {
  padding-top: 0;
  width: auto;
  height: 30px;
  font-size: 14px;
  font-weight: 200;
  color: #FFFFFF;
  border-radius: 18px;
  border: none;
  background: #23C3F3; }

.action-buttons {
  display: none !important;
  background-color: #efefef;
  border: 2px solid #dfdfdf;
  -webkit-transform: translate(0, 24px);
          transform: translate(0, 24px); }

.skill-wrapper:hover .action-buttons {
  position: absolute;
  display: inline-block !important;
  margin-top: 35px; }

.action-buttons:hover {
  position: absolute;
  display: inline-block !important;
  margin-top: 35px; }

.skill-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 16px;
  padding: 16px 20px;
  height: 48px;
  font-size: 16px;
  border-radius: 24px;
  background: #dfdfdf;
  color: #5B5B5B;
  cursor: pointer; }

.skill-wrapper:hover {
  color: #5B5B5B;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 16px;
  padding: 16px 20px;
  height: 48px;
  font-size: 16px;
  border-radius: 24px;
  background: #cbcbcb;
  cursor: pointer;
  box-shadow: #9A9A9A; }

.skill-wrapper.level {
  padding-left: 8px; }

.skill-wrapper.selected {
  background: #23C3F3;
  color: white; }

.skill-wrapper.level span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  font-size: 10px;
  border-radius: 50%;
  background: #FFFFFF; }

.skill-wrapper.orange span {
  color: #FA6400; }

.skill-wrapper.blue span {
  color: #23C3F3; }

.skill-wrapper.magenta span {
  color: #F03D75; }

.skill-wrapper.green span {
  color: #48C63F; }

.skill-wrapper.purple span {
  color: #5C3FC1; }

.skill-wrapper.orange {
  color: white;
  background: #FA6400; }

.skill-wrapper.blue {
  color: white;
  background: #23C3F3; }

.skill-wrapper.magenta {
  color: white;
  background: #F03D75; }

.skill-wrapper.green {
  color: white;
  background: #48C63F; }

.skill-wrapper.purple {
  color: white;
  background: #5C3FC1; }

.skillButton {
  display: block; }

.skillButtonNone {
  display: none; }

.padding-around {
  padding: 40px; }

.card-style {
  box-shadow: 2px 2px 5px black; }

.hide-overflow {
  width: inherit;
  height: inherit;
  overflow-x: scroll;
  overflow-y: scroll; }

.CSV-button {
  float: right;
  margin-bottom: 5px; }

.fontHead {
  font-size: large; }

body {
  background-color: #F9FAFC !important; }

.pointerCursor {
  cursor: pointer; }

.noneDisplay {
  display: none; }

.fontWeight400 {
  font-weight: 400; }

.fontWeight600 {
  font-weight: 600; }

.fontWeight700 {
  font-weight: 700; }

.fontWeight900 {
  font-weight: 900; }

body {
  font-family: poppins;
  color: #4e6174; }

/* Individual & Organization */
.common-left-menu {
  color: #a6b0b9;
  text-align: left; }

/* ENDS Individual & Organization */
.whiteHeading {
  padding: 10vh;
  background-color: white;
  text-align: left;
  padding-bottom: 1vh; }

.insight-common-img {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%); }

.insights-image-text,
.data-insight-image,
.data-insight-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.data-insight-video {
  background-color: white;
  border-radius: 5px;
  height: 50vh;
  width: inherit; }

.insight-video-image {
  border-radius: 5px;
  margin-bottom: 0rem;
  width: inherit;
  height: inherit; }

.insight-video-title {
  color: #d6dbdf;
  font-size: 0.9rem; }

.insights-image-left {
  border-radius: 10px;
  width: inherit;
  height: inherit; }

.loader-inBetween {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;
  font-size: 16px;
  padding: 2px 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px; }

.arrowImage {
  text-align: center; }

.arrow {
  float: left;
  background-size: 150px 200px;
  height: 200px;
  width: 150px; }

.arrow:hover {
  box-shadow: 2px 2px 5px black;
  cursor: pointer; }

.arrow1 {
  background-image: url("/assets/img/arrow1.png"); }

.arrow2 {
  background-image: url("/assets/img/arrow2.png"); }

.arrow3 {
  background-image: url("/assets/img/arrow3.png"); }

.arrow4 {
  background-image: url("/assets/img/arrow4.png"); }

.arrow5 {
  background-image: url("/assets/img/arrow5.png"); }

.arrow6 {
  background-image: url("/assets/img/arrow6.png"); }

.leftDiv {
  padding-top: 170px;
  text-align: center;
  color: white;
  height: 468px;
  width: 100%;
  background-color: #4e6174; }

.leftDiv:hover {
  box-shadow: 2px 2px 5px black;
  cursor: pointer; }

.pagination > li > a,
.pagination > li > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 20px;
  color: red;
  height: 10px;
  margin: 0 2px;
  min-width: 36px;
  padding: 8px 12px;
  font-weight: bolder; }

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
  background-color: red;
  border-color: black;
  color: white; }

.bulb-image {
  border-radius: 50%;
  background-color: #eceef0;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }

.select-account {
  font-size: 14px;
  font-weight: 600;
  text-align: center; }

.select-profile-item {
  box-shadow: 0px 0px 8px 0px #80808052;
  margin: 18px;
  padding: 8px 12px;
  border-radius: 6px;
  align-items: center;
  min-width: 240px;
  cursor: pointer; }
  .select-profile-item:hover {
    box-shadow: 0px 0px 8px 4px #80808052; }
  .select-profile-item .MuiAvatar-root {
    margin: 5px; }
  .select-profile-item div.info {
    margin-left: 6px; }
    .select-profile-item div.info label.name {
      margin: 0;
      font-size: 14px;
      font-weight: 500; }
    .select-profile-item div.info span.type {
      font-size: 12px;
      font-weight: 600; }
      .select-profile-item div.info span.type p {
        margin: 0; }
      .select-profile-item div.info span.type.b2b {
        color: #09ceba; }
      .select-profile-item div.info span.type.b2c {
        color: #911d25; }

.load__icon {
    -webkit-animation: linear load 2s infinite;
            animation: linear load 2s infinite;
    width: 32px;
    height: 32px;
}

.load__icon-wrap {
    margin: auto;
}

.load {
    height: calc(100vh - 16px);
    width: 100%;
    display: flex;
    align-items: center;
}

@-webkit-keyframes load {
    from {
        -webkit-transform: rotate(0deg) scale(2);
                transform: rotate(0deg) scale(2);
    }
    to {
        -webkit-transform: rotate(360deg) scale(2);
                transform: rotate(360deg) scale(2);
    }
}

@keyframes load {
    from {
        -webkit-transform: rotate(0deg) scale(2);
                transform: rotate(0deg) scale(2);
    }
    to {
        -webkit-transform: rotate(360deg) scale(2);
                transform: rotate(360deg) scale(2);
    }
}

.carousel-main {
  margin: 7vh;
  background: #142133;
  border-radius: 12px;
  padding: 80px; }

.carousel-img {
  height: 100%;
  width: 100%; }

.carousel-tabs {
  margin: 20px 40px 20px 40px;
  display: flex;
  justify-content: center; }

.carousel-text {
  color: #FFFFFF;
  height: 100%;
  align-items: center;
  display: flex;
  margin-left: 20px; }

.carousel-text-title {
  font-weight: 800;
  font-size: 2.5rem !important; }

.carousel-tab-select {
  background-color: #FFFFFF;
  height: 4px;
  width: 50px;
  margin: 10px;
  top: 4px;
  cursor: pointer; }

.carousel-tab-selected {
  background-color: #0884FF;
  height: 6px;
  margin-top: 9px; }

.viewDesktop {
  display: block; }

.viewMobile {
  display: none; }

.carousel-text-content {
  font-size: 22px; }

.carousel-text-content-learn {
  font-size: 22px; }

.viewMobileArrow {
  display: none; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .carousel-main {
    margin: 20px 0vh 0vh 0vh;
    padding: 20px;
    border-radius: 0px; }
  .remove-col-pad {
    padding: 0px; }
  .carousel-text-title {
    font-size: 16px !important; }
  .carousel-text-content {
    font-size: 14px !important; }
  .carousel-text-content-learn {
    font-size: 14px !important;
    color: #0884FF; }
  .carousel-text {
    display: block;
    margin-left: 0px; }
  .viewDesktop {
    display: none; }
  .viewMobile {
    display: block; }
  .viewMobileArrow {
    display: contents; } }

/* .bar { 
    fill: steelblue; 
} */
.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.bar {
  /* fill: orange; */
  fill: rgb(231, 233, 236);
  cursor: pointer;
}

.bar:hover {
  /* fill: orangered ; */
  fill: rgb(78, 97, 116);
}

.x.axis path {
  display: none;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
.chart-container .head .title {
  font-weight: 600;
  font-size: 18px;
  color: #3A414B; }

.chart-container .head .filters {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; }
  .chart-container .head .filters .MuiAutocomplete-root {
    margin-right: 10px;
    min-width: 150px; }

.chart-datalabel {
  background-color: red; }

.quick-facts-select {
  border: 1px solid white;
  width: 100%;
  color: white !important; }
  .quick-facts-select .MuiSelect-icon {
    color: white !important; }

.post-details-page {
  height: 80vh; }
  .post-details-page .page-body {
    padding-top: 20px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .post-details-page .page-body .back-icon {
      margin-left: 20px;
      margin-bottom: 30px;
      font-size: xx-large; }
    .post-details-page .page-body .body__image {
      display: flex;
      flex-flow: column;
      align-items: center; }
      .post-details-page .page-body .body__image .inner__container {
        height: 400px;
        width: 95%; }
        .post-details-page .page-body .body__image .inner__container img {
          border-radius: 16px;
          height: 100%;
          width: 100%; }
    .post-details-page .page-body .body__title {
      margin: 10px 0;
      text-align: left;
      margin: 10px 39px; }
    .post-details-page .page-body .body__description {
      text-align: justify;
      margin: 10px 39px; }
  .post-details-page .page-body::-webkit-scrollbar {
    display: none; }

.posts-page-container {
  margin: 0 35px;
  margin-bottom: 35px; }
  .posts-page-container .posts-heading {
    font-weight: 700;
    font-size: x-large;
    color: #4e6174;
    margin-top: 100px; }
  .posts-page-container .post-video-container,
  .posts-page-container .post-image-container {
    margin-bottom: 40px; }
    .posts-page-container .post-video-container h5,
    .posts-page-container .post-image-container h5 {
      font-size: 20px;
      line-height: 1.1;
      margin-bottom: 5px; }
  .posts-page-container .post-video {
    height: 300px;
    border-radius: 15px; }
  .posts-page-container .post-link {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 20px; }
    .posts-page-container .post-link h5 {
      font-size: 20px;
      line-height: 1.1;
      margin-bottom: 5px; }
  .posts-page-container .links-data {
    margin: auto 0; }
  .posts-page-container .insight-common-img {
    border-radius: 10px; }
  .posts-page-container .post__description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    margin: 0 0 1em 0;
    overflow: hidden;
    margin: 0;
    font-size: 14px;
    line-height: 1.1; }

.logo-div {
    background: #fafafa;
}
.main-form {
    text-align: center;
    justify-content: center;
}



.main-form {
	text-align: center;
	justify-content: center;
}

.account-type,
.org-type {
	display: flex;
	width: 80%;
	margin: 0 auto;
	/*margin-bottom: 20px;*/
	justify-content: space-between;
}

.org-type {
	/*margin-top: 20px;*/
	margin-bottom: -5px;
}

.TandC {
	margin-top: 15px;
}

.TandC > p {
	margin-bottom: 0;
}

.TandC > p > strong > a {
	text-decoration: underline;
	font-weight: lighter;
	color: black;
}

.btn-class {
	display: block;
	width: 100%;
}

.error_msg {
	text-align: left;
    color: red;
    font-size: smaller;
}
.page-protector {
  height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white; }

.mission-control body {
  background: white !important; }

.legacy-TS-div {
  background: rgba(60, 136, 255, 0.23);
  border-radius: 4px;
  padding: 10px; }

.new-TS-div {
  background: rgba(255, 0, 0, 0.38);
  border-radius: 4px;
  padding: 10px; }

.ts-admin-url {
  word-break: break-word; }

.SignInPage {
  height: 100vh;
  color: #ffffff; }
  .SignInPage .col {
    padding: 0; }
  .SignInPage .row {
    margin: 0; }
  .SignInPage .left__container {
    background-color: #45cfba;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .SignInPage .left__container .heading {
      position: relative;
      top: 45%; }
      .SignInPage .left__container .heading img {
        height: 50px; }
    .SignInPage .left__container .description {
      position: relative;
      top: 47%;
      text-align: center; }
    .SignInPage .left__container .signIn__btn {
      position: relative;
      text-decoration: none;
      top: 70%;
      padding: 10px 28px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      color: #45cfba;
      background-color: #ffffff; }
    .SignInPage .left__container .contact_sales {
      position: relative;
      top: 73%;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #ffffff; }
      .SignInPage .left__container .contact_sales .details__container b {
        font-weight: 800;
        cursor: pointer; }
      .SignInPage .left__container .contact_sales .arrow__container {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        transition: -webkit-transform 200ms linear;
        transition: transform 200ms linear;
        transition: transform 200ms linear, -webkit-transform 200ms linear; }
        .SignInPage .left__container .contact_sales .arrow__container .arrow_line {
          visibility: hidden;
          display: block;
          width: 0;
          height: 1px;
          color: white;
          border: solid white;
          border-width: 1px;
          -webkit-transform: translateX(7px);
                  transform: translateX(7px);
          transition: width 200ms linear; }
        .SignInPage .left__container .contact_sales .arrow__container .arrow_pointer {
          visibility: hidden;
          border: solid white;
          height: 10px;
          width: 0;
          border-width: 0 3px 3px 0;
          padding: 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg); }
    .SignInPage .left__container .contact_sales:hover .arrow__container {
      -webkit-transform: translateX(37px);
              transform: translateX(37px); }
      .SignInPage .left__container .contact_sales:hover .arrow__container .arrow_line {
        visibility: visible;
        width: 30px; }
      .SignInPage .left__container .contact_sales:hover .arrow__container .arrow_pointer {
        visibility: visible;
        width: 10px; }
  .SignInPage .email__container {
    position: relative;
    top: 74%;
    font-weight: 700;
    cursor: pointer; }
  .SignInPage .right__container {
    background-color: #961C1E;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .SignInPage .right__container .heading {
      position: relative;
      top: 45%; }
      .SignInPage .right__container .heading img {
        height: 60px; }
    .SignInPage .right__container .description {
      position: relative;
      top: 47%; }
    .SignInPage .right__container .signIn__btn {
      position: relative;
      text-decoration: none;
      top: 68%;
      padding: 10px 28px;
      border-radius: 5px;
      font-size: 14px;
      color: #ffffff;
      background-color: #0d90fa; }
    .SignInPage .right__container .signUp__div {
      position: relative;
      top: 70%; }
      .SignInPage .right__container .signUp__div a {
        text-decoration: none;
        color: #ffffff;
        font-weight: 800; }

@media only screen and (max-width: 600px) {
  .SignInPage .left__container,
  .SignInPage .right__container {
    height: 100vh; }
  .SignInPage .left__container .heading {
    top: 38%; }
  .SignInPage .left__container .description {
    top: 40%; }
  .SignInPage .left__container .contact_sales {
    top: 75%;
    flex-flow: column; }
    .SignInPage .left__container .contact_sales .arrow__container {
      display: none; }
  .SignInPage .right__container .heading {
    top: 38%; }
  .SignInPage .right__container .description {
    top: 39%; }
  .SignInPage .right__container .signIn__btn {
    top: 68%; } }

@media (max-width: 768px) {
  .SignInPage .right__container .signIn__btn {
    top: 70%; }
  .SignInPage .right__container .signUp__div {
    top: 73%; } }

@media (max-width: 767px) {
  .left__container {
    height: 50%; }
  .right__container {
    height: 50%; } }

.viewAccelticDivMobile {
  display: none !important; }

.viewAccelticDivDesktop {
  display: flex !important; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .SignInPage .left__container, .SignInPage .right__container {
    height: 50vh; }
  .SignInPage .right__container .heading {
    top: 38%; }
  .SignInPage .right__container .heading img {
    width: 165px; }
  .SignInPage .left__container .heading img {
    height: 35px; }
  .SignInPage .right__container .description {
    top: 40%;
    font-size: 10px; }
  .SignInPage .left__container .heading {
    top: 38%; }
  .SignInPage .left__container .description {
    top: 40%;
    font-size: 10px; }
  .SignInPage .right__container {
    padding-left: 15px;
    padding-right: 15px; }
  .SignInPage .left__container .signIn__btn {
    top: 60%;
    width: 100%;
    text-align: center; }
  .SignInPage .right__container .signIn__btn {
    top: 55%;
    width: 100%;
    text-align: center;
    color: #961C1E;
    background-color: #ffffff;
    font-weight: 700; }
  .SignInPage .left__container .contact_sales {
    top: 62%; }
  .SignInPage .right__container .signUp__div {
    top: 62%;
    font-size: 14px; }
  .details__container {
    font-size: 14px; }
  .SignInPage .right__container .signIn__btn {
    top: 60%; }
  .SignInPage .email__container {
    top: 20%; }
  .viewAccelticDivMobile {
    display: flex !important; }
  .viewAccelticDivDesktop {
    display: none !important; } }

@media screen and (max-width: 320px) {
  .SignInPage .left__container, .SignInPage .right__container {
    height: 55vh; } }

.SignUpPage {
  height: 100vh;
  color: #ffffff; }
  .SignUpPage .col {
    padding: 0; }
  .SignUpPage .row {
    margin: 0; }
  .SignUpPage .left__container {
    background-color: #45cfba;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .SignUpPage .left__container .heading {
      position: relative;
      top: 45%; }
      .SignUpPage .left__container .heading img {
        height: 50px; }
    .SignUpPage .left__container .description {
      position: relative;
      top: 47%;
      text-align: center; }
    .SignUpPage .left__container .contact_sales {
      position: relative;
      top: 78%;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #ffffff; }
      .SignUpPage .left__container .contact_sales .details__container b {
        font-weight: 800;
        cursor: pointer; }
      .SignUpPage .left__container .contact_sales .arrow__container {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        transition: -webkit-transform 200ms linear;
        transition: transform 200ms linear;
        transition: transform 200ms linear, -webkit-transform 200ms linear; }
        .SignUpPage .left__container .contact_sales .arrow__container .arrow_line {
          visibility: hidden;
          display: block;
          width: 0;
          height: 1px;
          color: white;
          border: solid white;
          border-width: 1px;
          -webkit-transform: translateX(7px);
                  transform: translateX(7px);
          transition: width 200ms linear; }
        .SignUpPage .left__container .contact_sales .arrow__container .arrow_pointer {
          visibility: hidden;
          border: solid white;
          height: 10px;
          width: 0;
          border-width: 0 3px 3px 0;
          padding: 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg); }
    .SignUpPage .left__container .contact_sales:hover .arrow__container {
      -webkit-transform: translateX(37px);
              transform: translateX(37px); }
      .SignUpPage .left__container .contact_sales:hover .arrow__container .arrow_line {
        visibility: visible;
        width: 30px; }
      .SignUpPage .left__container .contact_sales:hover .arrow__container .arrow_pointer {
        visibility: visible;
        width: 10px; }
    .SignUpPage .left__container .email__container {
      position: relative;
      top: 79%;
      font-weight: 700;
      cursor: pointer; }
  .SignUpPage .right__container {
    background-color: #961C1E;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .SignUpPage .right__container .heading {
      position: relative;
      top: 45%; }
      .SignUpPage .right__container .heading img {
        height: 60px; }
    .SignUpPage .right__container .description {
      position: relative;
      top: 47%; }
    .SignUpPage .right__container .signUp__btn {
      position: relative;
      text-decoration: none;
      top: 77%;
      padding: 10px 28px;
      border-radius: 5px;
      font-size: 14px;
      color: #ffffff;
      background-color: #0d90fa; }

@media only screen and (max-width: 600px) {
  .SignUpPage .left__container,
  .SignUpPage .right__container {
    height: 100vh; }
  .SignUpPage .left__container .contact_sales {
    top: 75%;
    display: flex;
    flex-flow: column;
    align-items: center; }
  .SignUpPage .right__container .signUp__btn {
    top: 68%; } }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .SignUpPage .left__container, .SignUpPage .right__container {
    height: 50vh; }
  .SignUpPage .right__container {
    padding-left: 15px;
    padding-right: 15px; }
  .SignUpPage .right__container .heading img {
    width: 165px; }
  .SignUpPage .left__container .heading img {
    height: 35px; }
  .SignUpPage .right__container .heading {
    top: 38%; }
  .SignUpPage .right__container .description {
    top: 38%;
    font-size: 10px; }
  .SignUpPage .left__container .heading {
    top: 38%; }
  .SignUpPage .left__container .contact_sales {
    top: 50%; }
  .SignUpPage .right__container .signUp__btn {
    top: 50%;
    width: 100%;
    text-align: center;
    color: #961C1E;
    background-color: #ffffff;
    font-weight: 700; }
  .SignUpPage .left__container .email__container {
    top: 50%; }
  .SignUpPage .left__container .description {
    top: 38%;
    font-size: 10px; }
  .SignUpPage .email__container {
    top: 20%; }
  .viewAccelticDivMobile {
    display: flex !important; }
  .viewAccelticDivDesktop {
    display: none !important; } }

