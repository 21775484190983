.SignUpPage {
    height: 100vh;
    color: #ffffff;

    .col {
        padding: 0;
    }

    .row {
        margin: 0;
    }

    .left__container {
        background-color: #45cfba;
        display: flex;
        align-items: center;
        flex-direction: column;

        .heading {
            position: relative;
            top: 45%;

            img {
                height: 50px;
            }
        }

        .description {
            position: relative;
            top: 47%;
            text-align: center;
        }

        .contact_sales {
            position: relative;
            top: 78%;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #ffffff;

            .details__container {
                b {
                    font-weight: 800;
                    cursor: pointer;
                }
            }

            .arrow__container {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                transform: translateX(0);
                transition: transform 200ms linear;

                .arrow_line {
                    visibility: hidden;
                    display: block;
                    width: 0;
                    height: 1px;
                    color: white;
                    border: solid white;
                    border-width: 1px;
                    transform: translateX(7px);
                    transition: width 200ms linear;
                }

                .arrow_pointer {
                    visibility: hidden;
                    border: solid white;
                    height: 10px;
                    width: 0;
                    border-width: 0 3px 3px 0;
                    padding: 3px;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }
            }
        }

        .contact_sales:hover {
            .arrow__container {
                transform: translateX(37px);

                .arrow_line {
                    visibility: visible;
                    width: 30px;
                }

                .arrow_pointer {
                    visibility: visible;
                    width: 10px;
                }
            }
        }

        .email__container {
            position: relative;
            top: 79%;
            font-weight: 700;
            cursor: pointer;
        }
    }

    .right__container {
        background-color: #961C1E;
        display: flex;
        align-items: center;
        flex-direction: column;

        .heading {
            position: relative;
            top: 45%;

            img {
                height: 60px;
            }
        }

        .description {
            position: relative;
            top: 47%;
        }

        .signUp__btn {
            position: relative;
            text-decoration: none;
            top: 77%;
            padding: 10px 28px;
            border-radius: 5px;
            font-size: 14px;
            color: #ffffff;
            background-color: #0d90fa;
        }
    }
}

@media only screen and (max-width: 600px) {
    .SignUpPage {
        .left__container,
        .right__container {
            height: 100vh;
        }

        .left__container {
            .contact_sales {
                top: 75%;
                display: flex;
                flex-flow: column;
                align-items: center;
            }
        }

        .right__container {
            .signUp__btn {
                top: 68%;
            }
        }
    }
}


@media screen and (min-width: 320px) and (max-width: 480px) { 
    .SignUpPage .left__container, .SignUpPage .right__container {
        height: 50vh;
    }
    .SignUpPage .right__container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .SignUpPage .right__container .heading img {
        width: 165px;
    }
    .SignUpPage .left__container .heading img {
        height: 35px;
    }
    .SignUpPage .right__container .heading {
        top: 38%;
    }
    .SignUpPage .right__container .description {
        top: 38%;
        font-size: 10px;
    }
    .SignUpPage .left__container .heading {
        top: 38%;
    }
    .SignUpPage .left__container .contact_sales {
        top: 50%;
    }
    .SignUpPage .right__container .signUp__btn {
        top: 50%;
        width: 100%;
        text-align: center;
        color: #961C1E;
        background-color: #ffffff;
        font-weight: 700;
    }
    .SignUpPage .left__container .email__container {
        top: 50%;
    }
    .SignUpPage .left__container .description {
        top: 38%;
        font-size: 10px;
    }
    .SignUpPage .email__container {
        top: 20%;
    }
    .viewAccelticDivMobile {
        display: flex !important;
    }
    .viewAccelticDivDesktop {
        display: none !important;
    }
    
}