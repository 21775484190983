.padding-around {
  padding: 40px;
}
.card-style {
  box-shadow:2px 2px 5px black;
}
.hide-overflow {
  width:inherit;
  height:inherit ;
  overflow-x: scroll;
  overflow-y: scroll;
}
.CSV-button {
  float:right;
  margin-bottom: 5px;
}
.fontHead {
  font-size: large;
}
