.carousel-main {
    margin: 7vh;
    background: #142133;
    border-radius: 12px;
    padding: 80px
}

.carousel-img {

    // transition: all 4s ease-out;
    // animation: normal ease-out;
    height: 100%;
    width: 100%;
}

.carousel-tabs {
    margin: 20px 40px 20px 40px;
    display: flex;
    justify-content: center;
}

.carousel-text {
    // transition: all 4s ease-out;
    color: #FFFFFF;
    height: 100%;
    align-items: center;
    display: flex;
    margin-left: 20px;
}

.carousel-text-title {
    font-weight: 800;
    font-size: 2.5rem !important;
}

.carousel-tab-select {
    background-color: #FFFFFF;
    height: 4px;
    width: 50px;
    margin: 10px;
    top: 4px;
    cursor: pointer;
}

.carousel-tab-selected {
    background-color: #0884FF;
    height: 6px;
    margin-top: 9px;
}

.viewDesktop {
    display: block;
}
.viewMobile {
    display: none;
}
.carousel-text-content {
    font-size: 22px;
}
.carousel-text-content-learn {
    font-size: 22px;
}
.viewMobileArrow {
    display: none;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
    .carousel-main {
        margin: 20px 0vh 0vh 0vh;
        padding: 20px;
        border-radius: 0px;
    }
    .remove-col-pad {
        padding:0px;
    }
    .carousel-text-title {
        font-size: 16px!important;
    }
    .carousel-text-content {
        font-size: 14px!important;
    }
    .carousel-text-content-learn {
        font-size: 14px!important;
        color:#0884FF
    }
    .carousel-text {
        display: block;
        margin-left: 0px;
    }
    .viewDesktop {
        display: none;
    }
    .viewMobile {
        display: block;
    }
    .viewMobileArrow {
        display: contents;
    }
    
}