
/* Footer */
.footer {
  padding-top:5px;
  font-weight: 900;
  font-size: small;
}
.footerLink {
  width: 100% !important;
  cursor: default;
  color:white;
  text-decoration: none;
}
.footerLink:hover {
  //color:#5b5b5b;
  //text-decoration: none;
}
