.posts-page-container {
  margin: 0 35px;
  margin-bottom: 35px;

  .posts-heading {
    font-weight: 700;
    font-size: x-large;
    color: #4e6174;
    margin-top: 100px;
  }

  .post-video-container,
  .post-image-container {
    margin-bottom: 40px;

    h5 {
      font-size: 20px;
      line-height: 1.1;
      margin-bottom: 5px;
    }
  }

  .post-video {
    height: 300px;
    border-radius: 15px;
  }

  .post-link {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 20px;

    h5 {
      font-size: 20px;
      line-height: 1.1;
      margin-bottom: 5px;
    }
  }

  .links-data {
    margin: auto 0;
  }

  .insight-common-img {
    border-radius: 10px;
  }

  .post__description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    margin: 0 0 1em 0;
    overflow: hidden;
    margin: 0;
    font-size: 14px;
    line-height: 1.1;
  }
}
