.chart-container {
  .head {
    //display: flex;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #3A414B;
    }

    .filters {
      margin-left: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .MuiAutocomplete-root {
        margin-right: 10px;
        min-width: 150px;
      }
    }
  }
}

.chart-datalabel {
  background-color: red;
}
