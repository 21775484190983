/* Data Insights and trends */
.insights {
	color: #4e6074;
	background-color: white;
	width: inherit;
	padding: 0vh 5vh;
	margin: 0px;
	font-size: medium;
}
.headingData {
	margin-bottom: 50px;
	font-size: x-large;
	color: #4e6074;
}
.trends {
	padding: 2vh;
	padding-top: 3vh;
	margin-top: 50px;
	background-color: #293343;
	color: white;
}
.below-visual {
	margin-bottom: 4rem;
}
.videobutton {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	background-color: #555;
	color: white;
	font-size: 16px;
	padding: 2px 14px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
}
.modalPlayer {
	max-width: 500px;
	width: auto;
	height: 50%;
	padding: unset;
	background: black;
}
.customWidth {
	height: 30% !important;
	width: 30% !important;
}
.customWidthList {
	height: 50% !important;
	width: 50% !important;
}
.customWidthImage {
	//width: 50% !important;
	height: inherit;
}
.customBackground {
	background-color: #f6f7f8 !important;
}
#video {
	object-fit: initial;
	width: inherit;
	height: inherit;
}
.insights-image-text {
	background-color: rgb(246, 247, 248);
	border-radius: 8px;
	//height: 100%;
	height: 97px;
	width: inherit;
}

// for mobile phones
@media screen and (min-width: 320px) and (max-width: 480px) {
	.insights {
		padding: 0vh 1vh;
		text-align: center;
	}
	.headingData {
		margin-bottom: 5rem;
	}
	.below-visual {
		margin-bottom: 4rem;
	}
	.customWidth {
		height: auto !important;
		width: auto !important;
	}
	.customWidthImage {
		width: 100% !important;
	}
	.customWidthList {
		height: 60px !important;
	}
	.insights-image-text {
		background-color: rgb(246, 247, 248);
		border-radius: 8px;
		height: 100%;
		//height: 97px;
		width: inherit;
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.insights-image-text {
		background-color: rgb(246, 247, 248);
		border-radius: 8px;
		height: 100%;
		//height: 97px;
		width: inherit;
	}
}
